/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { selectLocale } from "../features/localisation/localeSlice";
import { locales } from "../features/localisation/locales";
import { localise } from "../features/localisation/localiser";
import { LocaliserArgs } from "../features/localisation/types";
import { useAppSelector } from "../hooks";

export function useTranslate(key: string, args?: LocaliserArgs) {
  const locale = useAppSelector(selectLocale);
  return localise(key, locales, locale, args);
}

export function useTranslateFunction() {
  const locale = useAppSelector(selectLocale);
  return (key: string, args?: LocaliserArgs) => localise(key, locales, locale, args);
}
