/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Locales, LocaliserArgs } from "./types";

const getTranslation = (key: string, locales: Locales, locale: string): string | undefined => {
  let node = locales[locale.substring(0, 2)];

  if (!node) {
    throw new Error("invalid locale");
  }

  for (
    let [propertyName, ...propertyNames] = key.split(".");
    propertyName !== undefined;
    [propertyName, ...propertyNames] = propertyNames
  ) {
    const property = node[propertyName];

    if (typeof property === "string") {
      // If the key is too long we return undefined because it is not a proper match
      return propertyNames.length === 0 ? property : undefined;
    } else if (typeof property !== "object" || property === null) {
      // If the property is not a string or object then stop.
      // This is typically the case if the property is undefined...
      break;
    }

    node = property;
  }

  return undefined;
};

const replaceArgs = (translation: string, args: LocaliserArgs = {}): string => {
  let translationWithArgs = translation;
  for (const key in args) {
    const regEx = new RegExp("{" + key + "}", "g");
    translationWithArgs = translationWithArgs.replace(regEx, args[key]);
  }
  return translationWithArgs;
};

export const localise = (key: string, locales: Locales, locale: string, args?: LocaliserArgs): string => {
  const translation = getTranslation(key, locales, locale);
  if (translation) {
    return replaceArgs(translation, args);
  }
  return key;
};
