/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Locales } from "./types";

export const locales: Locales = {
  de: {
    detailPage: {
      title: "Rückfrage vom {formattedDate}",
      hint: "Bitte bearbeiten Sie die Rückfrage und senden die angeforderten Unterlagen zu.",
      response: {
        title: "Meine Antwort"
      },
      responseForm: {
        title: "Meine Antwort",
        emptyMessageValidation: "Bitte beantworten Sie die Rückfrage.",
        sendSuccess: "Erfolgreich versendet",
        sendFailed: "Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut.",
        sending: "Wird gesendet"
      },
      rueckfrage: {
        notFound: "Die Rückfrage mit id {id} wurde nicht gefunden."
      },
      uploadButton: "Datei hinzufügen",
      submitButton: "Antwort absenden",
      messagePlaceholder: "Fügen Sie eine Nachricht hinzu",
      messageBody: "Nachrichtentext",
      userInputAriaLabel: "Benutzereingabe",
      htmlTitle: "Rückfrage {formattedDate}"
    },
    overviewPage: {
      heading: "Postfach von {userName}",
      infoText: {
        empty: "Keine Rückfragen vorhanden",
        notEmpty: "Bitte bearbeiten Sie die Rückfragen zu Ihren Online-Anträgen:"
      },
      nothingToDo: "Alles erledigt - hier gibt es nichts zu tun.",
      nothingToDoIconAltText: "Haken",
      listItem: {
        title: "Rückfrage vom {formattedDate}",
        answeredAt: "Beantwortet am:\n{formattedDate}",
        status: {
          unread: "Antwort für Antrag erforderlich",
          disabled: "Antrag gesperrt",
          unreadAndDisabled: "Antrag gesperrt und Antwort erforderlich"
        },
        lockedIconAriaLabel: "Antrag gesperrt",
        button: {
          unread: "Antworten",
          read: "Öffnen"
        }
      },
      htmlTitle: "Postfach",
      toolTipText: {
        trustLevelHigh: {
          line1: 'Login-Methode mit Vertrauensniveau "Hoch" erforderlich.',
          line2: "Zum Öffnen dieser Nachricht loggen Sie sich mit ihrem Online-Ausweis oder Europäischer ID ein."
        },
        trustLevelSubstantial: {
          line1: 'Login-Methode mit Vertrauensniveau "Substantiell" erforderlich.',
          line2: "Zum Öffnen dieser Nachricht loggen Sie sich mit ihrem ELSTER-Konto oder Europäischer ID ein."
        }
      }
    },
    attachments: {
      error: {
        fileSize: "Die Datei ist zu groß (maximal 100MB).",
        fileType: "Der Datei-Typ ist nicht gültig (erlaubt sind .pdf, .doc(x), .xls(x), .png und .jp(e)g).",
        virus: "Die Datei enthält möglicherweise schädliche Inhalte.",
        network: "Die Datei konnte nicht hinzugefügt werden."
      }
    },
    common: {
      error: {
        notFound: {
          heading: "Seite nicht gefunden (404)",
          message: "Es tut uns leid, die aufgerufene Seite existiert nicht im Antragsraum."
        },
        generic: {
          message: "Es ist ein Fehler aufgetreten."
        },
        goBackTo: "Bitte gehen Sie zurück zu Ihrem",
        linkText: "Postfach",
        notFoundIconAltText: "Seite nicht gefunden Icon",
        errorIconAltText: "Fehler Icon"
      },
      loadingMessage: "Seite wird geladen",
      open: "Öffnen",
      new: "neu",
      notFound: "Seite nicht gefunden.",
      privacyPolicy: "Datenschutz",
      imprint: "Impressum",
      leftArrow: "Linker Pfeil",
      upload: "Hochladen",
      pageHeaderAriaLabel: "Antragsraum, Nachrichtenaustausch zu Online-Anträgen",
      logoAltText: "Antragsraum Logo",
      userIconAltText: "Benutzer Icon",
      bayernIdIconAltText: "BayernID Icon",
      ozgCloudIconAltText: "OZG Cloud Logo",
      close: "Schließen",
      htmlTitle: "Antragsraum"
    },
    auth: {
      signingIn: "Login-Vorgang läuft...",
      signingOut: "Logout-Vorgang läuft...",
      loading: "Lädt...",
      error: "Authentifizierungsproblem"
    }
  },
  en: {
    detailPage: {
      title: "Enquiry from {formattedDate}",
      hint: "Please process the query and send the requested documents.",
      response: {
        title: "My answer from {formattedDate}"
      },
      responseForm: {
        title: "My answer",
        emptyMessageValidation: "Please provide your reply to the query.",
        sendSuccess: "Successfully sent",
        sendFailed: "An error occured, please try again.",
        sending: "Sending"
      },
      rueckfrage: {
        notFound: "Die Rückfrage mit id {id} wurde nicht gefunden."
      },
      uploadButton: "Upload file",
      submitButton: "Submit",
      messagePlaceholder: "Add a message",
      messageBody: "Message body",
      userInputAriaLabel: "User input",
      htmlTitle: "Enquiry {formattedDate}"
    },
    overviewPage: {
      heading: "Inbox of {userName}",
      infoText: {
        empty: "No messages available",
        notEmpty: "Please process the queries regarding your online applications:"
      },
      nothingToDo: "All done - there's nothing to do here.",
      nothingToDoIconAltText: "Check mark",
      listItem: {
        title: "Reply from {formattedDate}",
        answeredAt: "Last answered at:\n{formattedDate}",
        status: {
          unread: "status unread",
          disabled: "status locked",
          unreadAndDisabled: "status unread and locked"
        }
      },
      htmlTitle: "Inbox",
      toolTipText: {
        trustLevelHigh: {
          line1: 'Login-method with Trustlevel "high" is needed.',
          line2: "To open this message log in with your online-ID or european-ID."
        },
        trustLevelSubstantial: {
          line1: 'Login-method with Trustlevel "substantial" is needed.',
          line2: "To open this message log in with your elster-account or european-ID."
        }
      }
    },
    attachments: {
      error: {
        fileSize: "The file is too big (maximum 100MB).",
        fileType: "The file type isn't valid (allowed are .pdf, .doc(x), .xls(x), .png and .jp(e)g).",
        virus: "The file possibly contains malicious content.",
        network: "The file couldn't be added."
      }
    },
    common: {
      error: {
        notFound: {
          heading: "Page not found (404)",
          message: "We are sorry, the requested page doesn't exist in the application room."
        },
        generic: {
          message: "An error occured."
        },
        goBackTo: "Please return to your",
        linkText: "inbox",
        notFoundIconAltText: "Page not found icon",
        errorIconAltText: "Error icon"
      },
      loadingMessage: "Loading",
      open: "Open",
      new: "new",
      notFound: "Page not found.",
      privacyPolicy: "Privacy Policy",
      imprint: "Imprint",
      leftArrow: "Left arrow",
      upload: "Upload",
      pageHeaderAriaLabel: "Application room, message exchange for online applications",
      logoAltText: "Application room logo",
      userIconAltText: "User icon",
      bayernIdIconAltText: "BayernID icon",
      ozgCloudIconAltText: "OZG cloud logo",
      close: "Close",
      htmlTitle: "Application room"
    },
    auth: {
      signingIn: "Logging you in...",
      signingOut: "Logging you out...",
      loading: "Loading...",
      error: "Authentication error"
    }
  }
};
