/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import styled from "styled-components";
import { COLORS, mobileMaxWidth, tabletMaxWidth } from "../../../../constants";
import { Text } from "../../../../Data Display/Text/Text";

export const Row = styled.a`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 32px;
  white-space: pre-line;
  @media screen and (max-width: ${mobileMaxWidth}px) {
    white-space: normal;
    flex-direction: column;
    gap: 16px;
  }
`;

export const AnsweredAtBox = styled.div`
  min-width: 140px;
`;

export const ButtonStylingWrapper = styled.div<{ disabled: boolean; unread: boolean }>`
  button,
  button:hover {
    width: 140px;
    padding: 12px 16px;
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 700;

    svg {
      margin-right: 8px;
      font-size: 1.125rem;
    }
  }

  button {
    background-color: ${(props) => {
      if (props.unread) {
        return props.disabled ? COLORS.DISABLED : COLORS.INTERACTION;
      }

      return props.disabled ? COLORS.DISABLED_LIGHT : COLORS.WHITE;
    }};

    color: ${(props) => {
      if (props.unread) {
        return props.disabled ? COLORS.DISABLED_LIGHT : COLORS.WHITE;
      }

      return props.disabled ? COLORS.DISABLED : COLORS.INTERACTION;
    }};

    border: ${(props) => {
      if (props.unread) {
        return "";
      }

      return props.disabled ? `1px solid ${COLORS.DISABLED}` : `1px solid ${COLORS.INTERACTION}`;
    }};
  }

  button:hover {
    background-color: ${(props) => {
      if (props.unread) {
        return props.disabled ? COLORS.DISABLED : COLORS.WHITE;
      }

      return props.disabled ? COLORS.DISABLED_LIGHT : COLORS.WHITE;
    }};

    color: ${(props) => {
      if (props.unread) {
        return props.disabled ? COLORS.DISABLED_LIGHT : COLORS.INTERACTION;
      }

      return props.disabled ? COLORS.DISABLED : COLORS.INTERACTION;
    }};

    border: ${(props) => {
      if (props.unread) {
        return props.disabled ? "" : `2px solid ${COLORS.INTERACTION}`;
      }

      return props.disabled ? `1px solid ${COLORS.DISABLED}` : `2px solid ${COLORS.INTERACTION}`;
    }};
  }
`;

export const StyledText = styled(Text)`
  text-align: end;
  font-weight: 500;

  @media screen and (max-width: ${tabletMaxWidth}px) {
    text-align: start;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  gap: 32px;

  @media screen and (max-width: ${mobileMaxWidth}px) {
    white-space: normal;
    flex-direction: column;
    gap: 16px;
  }
`;
