/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import axios from "axios";
import { parseJwt } from "../../lib/common/jwt-utils";
import { getRuntimeEnvProps } from "../../lib/env/env";
import { User } from "../types";
import { setAuthHeader } from "./authHeader";

let timeout: NodeJS.Timeout;

export const AUTH_FAILED = "authentication failed";
export const LOGOUT_FAILED = "logout failed";
export const CONFIG_ERROR = "Redirect url not configured";

const authClient = axios.create({
  baseURL: "/auth"
});

export const loginAndStartTokenRefresher = async (authCode: string) => {
  const data = { code: authCode };
  try {
    const response = await authClient.post("/authenticate", data);
    setUserAndStartTokenRefresher(response.data);
    return response.data;
  } catch {
    throw new Error(AUTH_FAILED);
  }
};

const setUserAndStartTokenRefresher = (token: any) => {
  const user = parseJwt(token);
  const authUser = { user, token };
  localStorage.setItem("user", JSON.stringify(authUser));
  setAuthHeader();
  userTokenRefresher(user);
};

export const userTokenRefresher = (user: User) => {
  if (timeout) {
    clearTimeout(timeout);
  }
  let refreshIn = getExpireInMs(user);
  setTimeout(async () => {
    const response: any = await getRefreshedUserTokenFromServer(getCurrentUserFromLocalStorage());
    if (response.data) {
      const token = response.data;
      setUserAndStartTokenRefresher(token);
    }
  }, refreshIn);
};

const getExpireInMs = (user: User) => {
  let now = new Date().getTime();
  return user.exp * 1000 - now;
};

const getRefreshedUserTokenFromServer = async (user: User) => {
  try {
    return await authClient.post("/refresh", { code: user.refreshcode });
  } catch (error) {
    // TODO: bei server error wird der Nutzer unterbrochen und ausgeloggt -> Strategie entwerfen und implementieren
    await logout();
    return error;
  }
};

export const logout = async () => {
  try {
    await authClient.get("/logout");

    localStorage.removeItem("user");
    window.location.reload();
  } catch {
    throw new Error(LOGOUT_FAILED);
  }
};

export const getCurrentUserFromLocalStorage = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) {
    const authUser = JSON.parse(userStr);
    return authUser.user;
  }

  return null;
};

export const redirectToSSO = () => {
  let env = getRuntimeEnvProps();
  if (env.REDIRECT_URL) {
    window.location.replace(env.REDIRECT_URL);
  } else {
    throw new Error(CONFIG_ERROR);
  }
};
