/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Antwort, Rueckfrage, RueckfrageHeader } from "../../api/types";
import {
  getRueckfrageHeadersOfPostfach,
  getRueckfrageOfPostfach,
  sendMessage
} from "../../api/rueckfragen/rueckfragenAPI";

export type RueckfrageStatus = "idle" | "loading" | "succeeded" | "failed";

export interface RueckfrageState {
  rueckfrageHeaderStatus: RueckfrageStatus;
  selectedRueckfrageStatus: RueckfrageStatus;
  rueckfrageHeaders: RueckfrageHeader[];
  selectedRueckfrage?: Rueckfrage;
}

const initialState: RueckfrageState = {
  rueckfrageHeaderStatus: "idle",
  selectedRueckfrageStatus: "idle",
  rueckfrageHeaders: []
};

const rueckfragenSlice = createSlice({
  name: "rueckfragen",
  initialState,
  reducers: {
    resetRueckfrageHeaderStatus: (state) => {
      state.rueckfrageHeaderStatus = "idle";
    },
    clearSelectedRueckfrage: (state) => {
      state.selectedRueckfrageStatus = "idle";
      state.selectedRueckfrage = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessageThunk.rejected, () => {})
      .addCase(sendMessageThunk.fulfilled, (state) => {
        state.selectedRueckfrageStatus = "idle";
      });

    builder
      .addCase(fetchRueckfrageHeadersThunk.pending, (state) => {
        state.rueckfrageHeaderStatus = "loading";
      })
      .addCase(fetchRueckfrageHeadersThunk.rejected, (state) => {
        state.rueckfrageHeaderStatus = "failed";
      })
      .addCase(fetchRueckfrageHeadersThunk.fulfilled, (state, action) => {
        state.rueckfrageHeaderStatus = "succeeded";
        state.rueckfrageHeaders = action.payload;
      });

    builder
      .addCase(fetchRueckfragenThunk.pending, (state) => {
        state.selectedRueckfrageStatus = "loading";
      })
      .addCase(fetchRueckfragenThunk.rejected, (state) => {
        state.selectedRueckfrageStatus = "failed";
      })
      .addCase(fetchRueckfragenThunk.fulfilled, (state, action) => {
        state.selectedRueckfrageStatus = "succeeded";
        state.selectedRueckfrage = action.payload;
      });
  }
});

export const { resetRueckfrageHeaderStatus, clearSelectedRueckfrage } = rueckfragenSlice.actions;
export const rueckfragenReducer = rueckfragenSlice.reducer;

export const fetchRueckfrageHeadersThunk = createAsyncThunk(
  "rueckfragen/fetchRueckfrageHeaders",
  async (postfachId: string) => await getRueckfrageHeadersOfPostfach(postfachId)
);

export const fetchRueckfragenThunk = createAsyncThunk(
  "rueckfragen/fetchRueckfrage",
  async (link: string) => await getRueckfrageOfPostfach(link)
);

interface SendMessagePayload {
  id: string;
  newMessage: Antwort;
}

export const sendMessageThunk = createAsyncThunk(
  "rueckfragen/sendMessage",
  async ({ id, newMessage }: SendMessagePayload) => {
    const message = await sendMessage(newMessage);
    return { id, message };
  }
);
