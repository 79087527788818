/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import styled from "styled-components";
import { Heading } from "../../components/Data Display/Heading/Heading";
import { Text } from "../../components/Data Display/Text/Text";

export const StyledDiv = styled.div`
  gap: 4px;
`;

export const StyledHeading = styled(Heading)`
  line-height: 1.75rem;
  height: 28px;
`;

export const StyledText = styled(Text)`
  line-height: 1.75rem;
  height: 28px;
`;

export const NoMessagesContainer = styled.div`
  display: flex;
  padding: 80px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const NoMessagesText = styled(Text)`
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
`;
