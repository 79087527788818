/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import axios from "axios";
import { isFileSizeValid, isFileTypeValid } from "./validation";

/**
 * Validates and uploads a new attachment
 *
 * @param vorgangId the ID of the entire Rueckfrage/Vorgang
 * @param nachrichtEventId the id of the nachricht event the nachricht header belongs to
 * @param file the file that should be uploaded
 * @returns a Promise that resolves on successful upload (with the ID of the uploaded file as a string)
 * and rejects on validation or upload errors (with a localization key as the Error's message)
 */
export async function uploadAttachment(vorgangId: string, nachrichtEventId: string, file: File): Promise<string> {
  if (!isFileSizeValid(file.size)) {
    throw new Error("attachments.error.fileSize");
  }

  if (!isFileTypeValid(file.type)) {
    throw new Error("attachments.error.fileType");
  }

  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(`/api/file/${vorgangId}/${nachrichtEventId}`, formData, {
      // the content type is needed since the header expects form data here (with the actual file being under the key "file")
      headers: { "Content-Type": "multipart/form-data" }
    });

    return response.data as string;
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      console.log("attachment upload failed with following error", error);
    }

    if (axios.isAxiosError(error) && error.response?.status === 406) {
      throw new Error("attachments.error.virus");
    }

    throw new Error("attachments.error.network");
  }
}

/**
 * Downloads an attachment
 *
 * @param nachrichtEventId the id of the nachricht event the nachricht header belongs to
 * @param nachrichtId the id of the nachricht the attachment belongs to
 * @param fileId id of the file that should be downloaded
 * @returns a Promise that resolves on successful download (with the file) and rejects on download
 * errors (with a localization key as the Error's message)
 */
export async function downloadAttachment(nachrichtEventId: string, nachrichtId: string, fileId: string): Promise<Blob> {
  try {
    const response = await axios.get(`/api/file/${nachrichtEventId}/${nachrichtId}/${fileId}`, {
      responseType: "blob",
      timeout: 300000
    });

    return new Blob([response.data]);
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      console.log("attachment download failed with following error", error);
    }

    throw new Error("attachments.error.network");
  }
}

export function saveDownloadedAttachmentToFile(blob: Blob, fileName: string): void {
  const objectUrl = window.URL.createObjectURL(blob);

  let anchor = document.createElement("a");
  anchor.href = objectUrl;
  anchor.download = fileName;
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
}
