/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import axios from "axios";
import { Antwort, Command, CommandReference, Rueckfrage, RueckfrageHeader } from "../types";

export const SEND_FAILED = "send failed";
export const STATUS_CHECK_FAILED = "sending status check failed";
export const GET_RUECKFRAGE_HEADERS_FAILED = "get rueckfrage headers failed";
export const GET_RUECKFRAGE_FAILED = "get rueckfrage failed";
export const SEND_TIMEOUT_IN_MS = 60 * 1000;
export const STATUS_CHECK_INTERVAL_IN_MS = 1000;
export const STATUS_CHECK_MAX_ATTEMPTS = 300;

export async function getRueckfrageHeadersOfPostfach(postfachId: string): Promise<RueckfrageHeader[]> {
  try {
    const rueckfrageHeaderResponse = await axios.get(`/api/rueckfragen/${postfachId}`);

    return rueckfrageHeaderResponse.data as RueckfrageHeader[];
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      console.log("reading of rueckfrage headers failed with the following error: ", error);
    }

    throw new Error(GET_RUECKFRAGE_HEADERS_FAILED);
  }
}

export async function getRueckfrageOfPostfach(link: string): Promise<Rueckfrage> {
  try {
    const url = new URL(link);
    const response = await axios.get(url.pathname);
    return response.data as Rueckfrage;
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      console.log("reading of topic failed with the following error: ", error);
    }

    throw new Error(GET_RUECKFRAGE_FAILED);
  }
}

export async function sendMessage(newMessage: Antwort): Promise<Antwort> {
  try {
    const response = await axios.put(`/api/antwort`, newMessage, { timeout: SEND_TIMEOUT_IN_MS });
    await checkSendStatus(response.data);

    return newMessage;
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      console.log("sending the message failed with following error: ", error);
    }

    throw new Error(SEND_FAILED);
  }
}

async function checkSendStatus(commandReference: CommandReference): Promise<void> {
  let attempts = 0;

  while (attempts <= STATUS_CHECK_MAX_ATTEMPTS) {
    const response = await axios.get(`/api/command/${commandReference.id}/${commandReference.nachrichtEventId}`);
    const command = response.data as Command;

    if (command.status === "FINISHED") {
      break;
    }

    if (command.status !== "PENDING") {
      throw new Error(STATUS_CHECK_FAILED);
    }

    attempts++;
    await new Promise((resolve) => setTimeout(resolve, STATUS_CHECK_INTERVAL_IN_MS));
  }
}
