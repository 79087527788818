/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import styled from "styled-components";
import { COLORS } from "../../../../../constants";

export const TooltipContainer = styled.div<{ showTooltip: boolean }>`
  visibility: ${(props) => !props.showTooltip && "hidden"};
  width: 100%;
  position: absolute;
  top: -60px;
  left: 0;
  white-space: pre-line;
`;

export const Tooltip = styled.div`
  display: flex;
  z-index: 1;
  width: 380px;
  margin: auto;
  padding: 0.625rem;
  background-color: ${COLORS.WHITE};
  border-radius: 8px;
  box-shadow: 1px 1px 1px 1px ${COLORS.LIGHT_GREY};
  color: black;

  svg {
    margin-right: 8px;
    width: 36px;
    height: 18px;
    color: ${COLORS.SLATE};
  }
`;

export const TooltipText = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;

  p {
    margin: 0;
  }

  p:first-child {
    margin-bottom: 4px;
  }
`;
