/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
/**
 * Frontend-side validation for the allowed file size. This is re-validated in the backend.
 *
 * @param fileSize the size of the file to be checked in bytes
 * @returns true if valid, false if invalid
 */
export function isFileSizeValid(fileSize: number): boolean {
  return fileSize <= 1024 * 1024 * 100;
}

/**
 * Frontend-side validation for the allowed file types. This is re-validated in the backend.
 *
 * @param contentType the Content-Type/MIME-Type of the file to be checked
 * @returns true if valid, false if invalid
 */
export function isFileTypeValid(contentType: string): boolean {
  const validMIMETypes = [
    "application/pdf", // .pdf
    "application/msword", // .doc
    "application/msexcel", // .xls
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "image/png", // .png
    "image/jpeg" // .jp(e)g
  ];
  return validMIMETypes.some((x) => x === contentType);
}
