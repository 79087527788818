/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
export const COLORS = {
  LAVENDER_MIST: "#e2e8f0",
  WHITE_SMOKE: "#f4f4f5",
  WHITE: "#fff",
  LIGHT_GREY: "#94a3b8",
  DIVIDER_GREY: "#cbd5e1",
  DISABLED: "#8391A5",
  DISABLED_LIGHT: "#F8FAFC",
  DISABLED_DARK: "#606F85",
  DISABLED_UNREAD_GREY: "#8291A5",
  SLATE: "#64748b",
  SLATE_700: "#334155",
  YELLOW: "#facc15",
  CARMINE_PINK: "#ef4444",
  ALABASTER: "#f9fafb",
  PDF: "#f40f02",
  DOCX: "#103f91",
  INTERACTION: "#007EB5",
  XLSX: "#1d6f42"
};

export const contentMaxWidth = 1200;
export const desktopMinWidth = 768;
export const tabletMaxWidth = desktopMinWidth - 1;
export const tabletMinWidth = 520;
export const mobileMaxWidth = tabletMinWidth - 1;
